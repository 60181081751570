import React, { useEffect, useState } from 'react'
import axios from "axios";
import { FaEye } from "react-icons/fa"; // Import the eye icon for viewing details
import "./OnlineUser.scss"; // Include your styling here
import apiService from '../../../global/common/environment';
import axiosInstance from '../../../global/common/interceptor';


const OnlineUser = () => {
    const [Users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null); // State to hold selected User details
    const [showDetails, setShowDetails] = useState(false); // State to control modal visibility

    const getAllUsers = async () => {
        try {
            const res = await axiosInstance.get(apiService.getUsers);
            setUsers(res.data);
            console.log(res.data, "Users");
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getAllUsers();
    }, []);

    const changeStatus = async (id, status) => {
        try {
            const res = await axiosInstance.put(apiService.getUsers(id), { verified: status });
            console.log(res.data);
            getAllUsers();
        } catch (err) {
            console.log(err);
        }
    };

    const handleViewDetails = (User) => {
        setSelectedUser(User);
        setShowDetails(true); // Show the modal
    };

    const handleCloseModal = () => {
        setShowDetails(false);
        setSelectedUser(null);
    };
  return (
      <div className="admin-dashboard-container">
                <h1 className="dashboard-title">MakeStudyOnline  Users</h1>
                <table className="admin-dashboard-table">
                    <thead>
                        <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Nationality</th>
                            <th>Phone</th>
                            
                            <th>View</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Users.length > 0 ? (
                            Users.map((User, index) => (
                                <tr key={index} className={`User-row ${User.verified || ""}`}>
                                    <td>{User?.firstName || "N/A"}</td>
                                    <td>{User?.lastName || "N/A"}</td>
                                    <td>{User?.email || "N/A"}</td>
                                    <td>{User?.nationality || "N/A"}</td>                                  
                                    <td>{User?.phoneNumber|| "N/A"}</td>                                  
                                    {/* <td>
                                        <span className={`status-badge ${User?.verified === "active" ? "active" : "inactive"}`}>
                                            {User?.verified ? User.verified.charAt(0).toUpperCase() + User.verified.slice(1) : "N/A"}
                                        </span>
                                    </td> */}
                                    <td>
                                        {/* <button className="toggle-status-btn" onClick={() => changeStatus(User._id, User?.verified === "active" ? "inactive" : "active")}>
                                            {User?.verified === "active" ? "inactive" : "active"}
                                        </button> */}
                                        <button className="view-details-btn" onClick={() => handleViewDetails(User)}>
                                            <FaEye />
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="12">No Users found.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
    
                {showDetails && selectedUser && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={handleCloseModal}>&times;</span>
                            <h2>User Details</h2>
                            <p><strong>First Name:</strong> {selectedUser?.firstName || "N/A"}</p>
                            <p><strong>Last Name:</strong> {selectedUser?.lastName || "N/A"}</p>
                            <p><strong>Email:</strong> {selectedUser?.email || "N/A"}</p>
                            <p><strong>Country:</strong> {selectedUser?.nationality|| "N/A"}</p>
                            <p><strong>Phone:</strong> {selectedUser?.phoneNumber || "N/A"}</p>
                            {/* <p><strong>Status:</strong> {selectedUser?.verified || "N/A"}</p> */}
                        </div>
                    </div>
                )}
            </div>
  )
}

export default OnlineUser
