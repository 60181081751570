import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./AdminDailog.scss";
import { FaUserShield } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiService from "../../../global/common/environment";

const AdminDailog = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const response = await axios.post(apiService.adminLogin, {
        username: email,
        password,
      });
      console.log(response);
      if (response.data == "Invalid credentials") {
        // Login successful
        toast.error("Invalid admin name or password!");

        // Reset the form
        setEmail("");
        setPassword("");

        // Wait for the toast to be displayed before navigating
        // setTimeout(() => {
        //   navigate("/");
        // }, 2000);
      } else if (response.data.result) {
        setEmail("");
        setPassword("");
        toast.success("Login Succesful !");
        localStorage.setItem("token", response.data.result.accessToken);
        setTimeout(() => {
          navigate("/admin/admin-dashboard");
        }, 2000);
      } else {
        // Handle other status codes
        setError("Invalid admin name or password.");
      }
    } catch (error) {
      if (error.response) {
        // Error from server response
        if (error.response.status === 401) {
          setError("Invalid admin name or password.");
        } else {
          setError("An error occurred. Please try again.");
        }
      } else if (error.request) {
        // No response from server
        setError("No response from server. Please try again.");
      } else {
        // Error setting up request
        setError(error.message || "An error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="full-screen-container"
      style={{
        // backgroundImage: "url('./assets/img/Webimg/background image 1920.jpg')",
        // backgroundImage:"url('/assets/img/Webimg/background image 1920.jpg')",
        backgroundImage: "url('/assets/img/Webimg/backgroundimage1920.jpg')",

        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ToastContainer />

      <div className="login-container">
        <h3 className="login-title">
          Admin Login
          <FaUserShield style={{ marginLeft: "10px" }} />
        </h3>
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              id="email"
              placeholder="Enter Email"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              placeholder="Enter Password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
          </div>
          <button type="submit" className="login-button" disabled={loading}>
            {loading ? "Logging in..." : "Submit"}
          </button>
          {error && <p className="error-message">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default AdminDailog;
