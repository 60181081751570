
import React from "react";
import { FaAsterisk, FaCashRegister, FaCertificate, FaChevronCircleDown, FaEdit, FaHandshake, FaOdnoklassnikiSquare, FaPaypal, FaStreetView, FaUniversity, FaUser } from "react-icons/fa";
import { FcCollaboration } from "react-icons/fc";
import { PiStudentBold } from "react-icons/pi";

import "./list.items.scss";
import { useNavigate } from "react-router-dom";

const ListItems = () => {
  const navigation=useNavigate();
  return (
    <div className="container">
      <div className="row">
        {/* First Row */}
        <div className="col-lg-4  col-xl-3 col-md-6 mb-4">
          <div className="list-item-card" onClick={()=>navigation('/admin/agentdisplay')} >
            <div>
              {/* agents */}
              <FcCollaboration style={{ fontSize: "80px" }} />
            </div>
            <div className="list-item-right">
              <div className="list-item-name">Agents</div>
              <div className="count animate__flip animate__animated animate__delay-1s">900</div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-xl-3  col-md-6 mb-4">
          <div className="list-item-card">
            <div>
              <PiStudentBold style={{ fontSize: "80px" }} />
            </div>
            <div className="list-item-right">
              <div className="list-item-name">Students</div>
              <div className="count animate__flip animate__animated animate__delay-1s">230</div>
            </div>
          </div>
        </div>
      {/* </div> */}

      {/* <div className="row"> */}
        {/* Second Row */}
        <div className="col-lg-4 col-xl-3  col-md-6 mb-4">
          <div className="list-item-card" onClick={()=>navigation('/admin/programtypes')}>
            <div>
              <FaUniversity style={{ fontSize: "80px" }} />
            </div>
            <div className="list-item-right">
              <div className="list-item-name">Programs Categories</div>
              <div className="count animate__flip animate__animated animate__delay-1s">100</div>
            </div>
          </div>
        </div>

        {/* <div className="col-lg-4   col-md-6 col-xl-3  mb-4">
          <div className="list-item-card" onClick={()=>navigation('/admin/programs')}>
            <div>
              <FaHandshake style={{ fontSize: "80px" }} />
            </div>
            <div className="list-item-right">
              <div className="list-item-name">Program <br/>Courses</div>
              <div className="count animate__flip animate__animated animate__delay-1s">100</div>
            </div>
          </div>
        </div> */}
        <div className="col-lg-4   col-md-6 col-xl-3  mb-4">
          <div className="list-item-card" onClick={()=>navigation('/admin/displayprograms')}>
            <div>
              <FaChevronCircleDown style={{ fontSize: "80px" }} />
            </div>
            <div className="list-item-right">
              <div className="list-item-name">View <br/>Courses</div>
              <div className="count animate__flip animate__animated animate__delay-1s">100</div>
            </div>
          </div>
        </div>
        <div className="col-lg-4   col-md-6 col-xl-3  mb-4">
          <div className="list-item-card" onClick={()=>navigation('/admin/online')}>
            <div>
              <FaEdit style={{ fontSize: "80px" }} />
            </div>
            <br/>
            <div className="list-item-right">
              <div className="list-item-name"> Makestudy Online Courses</div>
              <div className="count animate__flip animate__animated animate__delay-1s">100</div>
            </div>
          </div>
        </div>
        <div className="col-lg-4   col-md-6 col-xl-3  mb-4">
          <div className="list-item-card" onClick={()=>navigation('/admin/onlineuser')}>
            <div>
              <FaUser style={{ fontSize: "80px" }} />
            </div>
            <br/>
            <div className="list-item-right">
              <div className="list-item-name"> Makestudy Online Users</div>
              <div className="count animate__flip animate__animated animate__delay-1s">100</div>
            </div>
          </div>
        </div>
        <div className="col-lg-4   col-md-6 col-xl-3  mb-4">
          <div className="list-item-card" onClick={()=>navigation('/admin/payments')}>
            <div>
              <FaPaypal style={{ fontSize: "80px" }} />
            </div>
            <br/>
            <div className="list-item-right">
              <div className="list-item-name"> Makestudy Online payments</div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListItems;
