import axios from 'axios';
import apiService from './environment';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: apiService.baseUrlOnline,
  // baseURL: 'http://88.222.244.193:3000',
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request Interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    console.log('Request:', config);

    return config;
  },
  (error) => {
    console.error('Request Error:', error);
    return Promise.reject(error);
  }
);

// Response Interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Log the response
    console.log('Response:', response);

    return response;
  },
  (error) => {
    if (error.response) {
      console.error('Response Error:', error.response.data);

      if (error.response.status === 401) {
        localStorage.removeItem('token');
        if(window.location.href !== '/')   window.location.href = '/';
      }
    } else {
     if(window.location.href !== '/')   window.location.href = '/';
      console.error('Network Error:', error.message);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
