// const baseUrl="http://localhost:8080/api";
// const baseUrl = "http://88.222.244.193:8080/api";
const baseUrl='https://api.partners.makestudy.com:8443/api'


const authApis = {
  adminLogin: baseUrl + "/admin-auth/login",
  userLogin: baseUrl + "/users/login",
  userRegister: baseUrl + "/users/register",
  verifyOtp: baseUrl + "/users/verify-otp",
  setPassword: baseUrl + "/users/create-password",
  reSetPassword: baseUrl + "/users/reset-password",
  verifyOtpAndResetPassword: baseUrl + "/users/verify-otp-and-reset-password",
  editUser:(id)=> baseUrl + "/users/"+id,
  contactUs: baseUrl + "/users/contact-us",
};
const blogsApis = {
  // getBlogs:baseUrl+"/blogs",
  getBlogs: (limit, skip) =>
    baseUrl + "/blogs?limit=" + limit + "&skip=" + skip,
  getBlogDetails: (id) => baseUrl + "/blogs/" + id,
  updateBlog: (id) => baseUrl + "/blogs/update-blog/" + id,
  deleteBlog: (id) => baseUrl + "/blogs/delete-blog/" + id,
};
const agentInfoPageApis = {
 createAgentInfo: baseUrl + "/agents/create-agent-info",
 updateAgentInfo:(id)=> baseUrl + "/agents/edit-agent-info/"+id,
 getAllagentsInfo: baseUrl + "/agents/get-all-agent-info",
};
const programApis = {
  createProgram: baseUrl + "/programs",
  getPrograms: baseUrl + "/programs",
  getProgramDetails: (id) => baseUrl + "/programs/" + id,
  updateProgram: (id) => baseUrl + "/programs/" + id,
  deleteProgram: (id) => baseUrl + "/programs/" + id,
  getProgram: (id) => baseUrl + "/programs/" + id,
};
const programCourse={
  createProgramCourse: baseUrl + "/program-courses",
  getProgramCourse: baseUrl + "/program-courses",
  getProgramCourseDetails: (id) => baseUrl + "/program-courses/" + id,
  updateProgramCourse: (id) => baseUrl + "/program-courses/" + id,
  deleteProgramCourse: (id) => baseUrl + "/program-courses/" + id,
  getProgramCourseByProgramId: (id) => baseUrl + "/program-courses/" + id,
}
const studentsApis={
  createStudent: baseUrl + "/students",
  getStudents: baseUrl + "/students",
  updateStudent: (id) => baseUrl + "/students/" + id,
  deleteStudent: (id) => baseUrl + "/students/" + id,
  getStudent: (id) => baseUrl + "/students/" + id,
}
const baseUrlOnline="https://api.makestudy.online:8444"
const mSCourses={
  createCourse:baseUrlOnline+"/courses",
  getMscourse:baseUrlOnline+"/courses",
  getPresignedUrlforCourseImage: (filename)=>baseUrlOnline+`/courses/s3/presigned-urls?filename=${filename}`,
  updateMsCourse:(id)=>baseUrlOnline+"/courses/"+id,
  deleteMsCourse:(id)=>baseUrlOnline+"/courses/"+id,
  getUsers:baseUrlOnline+"/auth/all-users",
  getpayments: ({page, limit, mihpayid, startdate, enddate}) => {
    let url = baseUrlOnline + "/payment/payments-from-db";
    let params = [];
  
    if (startdate) params.push("startDate=" + startdate);
    if (enddate) params.push("endDate=" + enddate);
    if (mihpayid) params.push("mihpayid=" + mihpayid);
    if (page) params.push("page=" + page);
    if (limit) params.push("limit=" + limit);
  
    if (params.length > 0) {
      url += "?" + params.join("&");
    }
  
    return url;
  },
  getPaymentTransactionById: (transactionId) =>
    baseUrlOnline+`/payment/transaction/${transactionId}`,
}


const commonApis = {};
const apiService = { ...authApis, ...blogsApis, ...commonApis, ...programApis, ...agentInfoPageApis ,...programCourse ,...studentsApis,...mSCourses,baseUrlOnline};
export default apiService;
