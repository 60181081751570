import React, { useState, useEffect } from "react";
import axiosInstance from "../../../global/common/interceptor";
import { Button, Table, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { MdDelete, MdEdit, MdVisibility } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import apiService from "../../../global/common/environment";
import "./OnlineCourses.scss";

const OnlineCourses = () => {
  const [Courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null); // State for the selected course
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const navigate = useNavigate();

  const getCourses = async () => {
    try {
      const response = await axiosInstance.get(apiService.getMscourse);
      setCourses(response.data);
    } catch (error) {
      console.error("Error fetching Courses:", error);
    }
  };

  const deleteCourse = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Course!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axiosInstance.delete(apiService.deleteMsCourse(id));
          getCourses();
          Swal.fire("Deleted!", "Your Course has been deleted.", "success");
        } catch (error) {
          console.error("Error deleting Course:", error);
          Swal.fire(
            "Error!",
            "Failed to delete the Course. Please try again.",
            "error"
          );
        }
      } else {
        Swal.fire("Cancelled", "Your Course is safe!", "info");
      }
    });
  };

  useEffect(() => {
    getCourses();
  }, []);

  const handleEditRedirect = (courseId) => {
    navigate(`/admin/onlinedetailpage/${courseId}`);
  };

  const handleAddProgram = () => {
    navigate("/admin/onlinedetailpage");
  };

  const handleViewCourse = (course) => {
    setSelectedCourse(course); // Set the selected course
    setShowModal(true); // Show the modal
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCourse(null);
  };

  return (
    <div className="course-schema-form-container mt-5">
      <div className="d-flex justify-content-between">
        <h1>Online Course </h1>
        <Button variant="primary" onClick={handleAddProgram}>
          Add Online Courses
        </Button>
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Course Name</th>
            <th>Price</th>
            <th>Currency</th>
            <th>Description</th>
            <th>Collage Name</th>
            <th>Categories</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Courses.map((Course) => (
            <tr key={Course._id}>
              <td>{Course.title}</td>
              <td>{Course.price}</td>
              <td>{Course.currency}</td>
              <td
                dangerouslySetInnerHTML={{
                  __html:
                    Course.description?.length > 50
                      ? `${Course.description.substring(0, 50)}...`
                      : Course.description,
                }}
              ></td>
              <td>{Course.collageName}</td>
              <td>{Course.categories[0] || "NA"}</td>
              <td>
                <img
                  src={Course.image}
                  alt="Course"
                  style={{ width: "100px" }}
                />
              </td>
              <td>
                <Button
                  variant="warning"
                  onClick={() => handleEditRedirect(Course._id)}
                >
                  <MdEdit />
                </Button>
                <Button
                  variant="danger"
                  onClick={() => deleteCourse(Course._id)}
                  className="ms-2"
                >
                  <MdDelete />
                </Button>
                <Button
                  variant="info"
                  onClick={() => handleViewCourse(Course)}
                  className="ms-2"
                >
                  <MdVisibility />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for Viewing Course Details */}
      <Modal show={showModal} onHide={handleCloseModal} className="modal"
      
      >
        <Modal.Header closeButton>
          <Modal.Title>Course Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      
          {selectedCourse && (
            <div>
              <p>
                <strong>Title:</strong> {selectedCourse.title}
              </p>
              <p>
                <strong>Price:</strong> {selectedCourse.price}
              </p>
              <p>
                <strong>Duration:</strong> {selectedCourse.duration}
              </p>
              <p>
                <strong>language:</strong> {selectedCourse.language}
              </p>
              <p>
                <strong>Description:</strong>{" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: selectedCourse.description,
                  }}
                ></span>
              </p>
              <p>
                <strong>curriculum:</strong>{" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: selectedCourse.curriculum,
                  }}
                ></span>
              </p>
              <p>
                <strong>Requriments:</strong>{" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: selectedCourse.requirements,
                  }}
                ></span>
              </p>
              <p>
                <strong>Collage Name:</strong> {selectedCourse.collageName}
              </p>
              <p>
                <strong>Categories:</strong>{" "}
                {selectedCourse.categories.join(", ")}
              </p>
              <img
                src={selectedCourse.image}
                alt="Course"
                style={{ width: "100%" }}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default OnlineCourses;
