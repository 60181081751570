import React, { useEffect, useState } from "react";
import axiosInstance from "../../../global/common/interceptor";
import apiService from "../../../global/common/environment";
import { FaEye } from "react-icons/fa";
import "./Payments.scss";

const Payments = () => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await axiosInstance.get(apiService.getpayments({ page: 1, limit: 100 }));
        const paymentsData = Array.isArray(response.data.payments) ? response.data.payments : [];
        setPayments(paymentsData);
      } catch (err) {
        console.error(err);
        setError("Failed to fetch payments data");
      } finally {
        setLoading(false);
      }
    };

    fetchPayments();
  }, []);

  const fetchTransactionDetails = async (transactionId) => {
    setModalLoading(true);
    try {
      const response = await axiosInstance.get(
        apiService.getPaymentTransactionById(transactionId)
      );
      setModalData(response.data);
    } catch (err) {
      console.error(err);
      setError("Failed to fetch transaction details");
    } finally {
      setModalLoading(false);
    }
  };

  const closeModal = () => setModalData(null);

  if (loading) return <p>Loading payments...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="payments-container">
      <h1>User Payments</h1>
      <table>
        <thead>
          <tr>
            <th>S.No</th>
            <th>First Name</th>
            <th>Transaction ID</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Total Amount</th>
            <th>Product</th>
            <th>Status</th>
            <th>Items</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment, index) => (
            <tr key={payment._id}>
              <td>{index + 1}</td> {/* S.No column */}
              <td>{payment.firstname || "N/A"}</td>
              <td>{payment.txnid || "N/A"}</td>
              <td>{payment.phone || "N/A"}</td>
              <td>{payment.email || "N/A"}</td>
              <td>{payment.amount || "N/A"}</td>
              <td>{payment.productinfo || "N/A"}</td>
              <td>{payment.status || "N/A"}</td>
              <td>
                <ul>
                  {payment.items && payment.items.length > 0 ? (
                    payment.items.map((item) => (
                      <li key={item._id}>
                        Course ID: {item.courseId}, Price: {item.price}
                      </li>
                    ))
                  ) : (
                    <li>No items</li>
                  )}
                </ul>
              </td>
              <td>
                <FaEye
                  className="view-icon"
                  onClick={() => fetchTransactionDetails(payment.mihpayid)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {modalData && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal" onClick={(e) => e.stopPropagation()}>
           
          
              <span className="close-icon" onClick={closeModal}>
                &times;
              </span>
           
            <div className="modal-body">
            <h2>Transaction Details</h2>
              {modalLoading ? (
                <p>Loading transaction details...</p>
              ) : (
                <>
                 
                  <p><strong>Name:</strong> {modalData.firstname || "N/A"}</p>
                  <p><strong>Transaction ID:</strong> {modalData.txnid || "N/A"}</p>
                  <p><strong>Email:</strong> {modalData.email || "N/A"}</p>
                  <p><strong>Phone:</strong> {modalData.phone || "N/A"}</p>
                  <p><strong>Amount:</strong> {modalData.amount || "N/A"}</p>
                  <p><strong>Currency:</strong> {modalData.currency || "N/A"}</p>
                  <p><strong>Status:</strong> {modalData.status || "N/A"}</p>
                  <p><strong>Items:</strong></p>
                  <ul>
                    {modalData.items && modalData.items.length > 0 ? (
                      modalData.items.map((item) => (
                        <li key={item._id}>
                          Course ID: {item.courseId}, Price: {item.price}
                        </li>
                      ))
                    ) : (
                      <li>No items</li>
                    )}
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Payments;
