import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import axios from "axios";
import "react-quill/dist/quill.snow.css";
import "./OnlineDetailCourses.scss";
import apiService from "../../../global/common/environment";
import axiosInstance from "../../../global/common/interceptor.js";
import { handleImageUpload } from "../../../global/common/awsS3.js";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const OnlineDetailCourses = () => {
  const [formData, setFormData] = useState({});
  const [files, setFiles] = useState({});
  const [richTextValues, setRichTextValues] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [currentField, setCurrentField] = useState("");

  const { id } = useParams();
  const nav = useNavigate();
  const courseId = id;

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    ["link", "image", "video", "formula"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ["clean"],
  ];

  const fields = [
    { name: "title", label: "Program Title", type: "text", required: true },
    { name: "price", label: "Price", type: "text", placeholder: "Enter price" },
    {
      name: "currency",
      label: "Currency",
      type: "select",
      options: ["USD", "EUR", "INR", "GBP", "CAD"],
    },
    { name: "language", label: "Language", type: "text", placeholder: "Enter language" },
    { name: "duration", label: "Duration", type: "text", placeholder: "Enter duration" },
    { name: "categories", label: "Categories", type: "select", options: ["Masters", "Bachelors"] },
    { name: "viewon", label: "View On", type: "select", options: ["Y", "N"] },
    { name: "intake", label: "Intake", type: "text", placeholder: "Enter intake" },
    { name: "image", label: "Image", type: "file" },
    { name: "collageName", label: "Collage Name", type: "text", placeholder: "Enter Collage Name" },
  ];

  const editorFields = ["description", "requirements", "curriculum"];

  const handleInputChange = async (e, fieldName) => {
    if (e.target.type === "file") {
      const s3UrlOfImage = await handleImageUpload(e);
      setFiles({ ...files, [fieldName]: s3UrlOfImage });
    } else {
      setFormData({ ...formData, [fieldName]: e.target.value });
    }
  };

  const handleRichTextChange = (field, value) => {
    setRichTextValues({ ...richTextValues, [field]: value });
    // console.log(value,"value")
  };

  const openModal = (field) => {
    setCurrentField(field);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setCurrentField("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    const formPayload = new FormData();
    // console.log(formPayload,"payload")

    // Append form fields
    Object.keys(formData).forEach((key) => {
      formPayload.append(key, formData[key]);
    });

    // Append file fields
    Object.keys(files).forEach((key) => {
      formPayload.append(key, files[key]);
    });
    // console.log(richTextValues["description"],"richtext")
    // Append rich text content let
    Object.keys(richTextValues).forEach((key) => {
      formPayload.append(key, richTextValues[key]);
    });

 
  try {
    let response;
    if (courseId) {
      const editPayload = Object.assign(formData, richTextValues);
      // Update course (PUT request)
      response = await axiosInstance.put(apiService.updateMsCourse(courseId), editPayload);
    } else {
      // Create new course (POST request)
      response = await axiosInstance.post(apiService.createCourse, formPayload);
    }

    // Display success message using Swal
    Swal.fire({
      title: "Success!",
      text: courseId ? "Course updated successfully!" : "Course added successfully!",
      icon: "success",
      confirmButtonText: "OK",
    });

    // Navigate to the course list page
    nav("/admin/online");
  } catch (error) {
    console.error("Error:", error.response?.data || error.message);

    // Display error message using Swal
    Swal.fire({
      title: "Error!",
      text: "Failed to submit the form. Please try again.",
      icon: "error",
      confirmButtonText: "OK",
    });
  }
};

  const fetchCourseData = async () => {
    if (courseId) {
      try {
        const response = await axios.get(`${apiService.getMscourse}/${courseId}`);
        setFormData(response.data);
        setRichTextValues({
          description: response.data.description,
          requirements: response.data.requirements,
          curriculum: response.data.curriculum,
        });
      } catch (error) {
        console.error("Error fetching course data:", error);
      }
    }
  };

  useEffect(() => {
    if (courseId) {
      fetchCourseData();
    }
  }, [courseId]);



  return (
    <div className="program-form">
      <h2>{courseId ? "Update Program" : "Add New Courses"}</h2>
      <form onSubmit={handleSubmit}>
        {fields.map((field) => (
          <div key={field.name} className="form-group">
            <label>{field.label}</label>
            {field.type === "file" ? (
              <input
                type="file"
                name={field.name}
                onChange={(e) => handleInputChange(e, field.name)}
              />
            ) : field.type === "select" ? (
              <select
                name={field.name}
                onChange={(e) => handleInputChange(e, field.name)}
                required={field.required}
                value={formData[field.name] || ""}
              >
                <option value="">Select</option>
                {field.options.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            ) : (
              <input
                type={field.type}
                name={field.name}
                onChange={(e) => handleInputChange(e, field.name)}
                required={field.required}
                value={formData[field.name] || ""}
              />
            )}
          </div>
        ))}

        {editorFields.map((field) => (
          <div key={field} className="form-group">
            <label>{field.charAt(0).toUpperCase() + field.slice(1)}</label>
            <button type="button" className="btns" onClick={() => openModal(field)}>
              Add {field.charAt(0).toUpperCase() + field.slice(1)}
            </button>
          </div>
        ))}

        <button type="submit" className="submit-btn">
          {courseId ? "Update" : "Submit"}
        </button>
      </form>

      {modalVisible && (
        <div className="modal visible">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>
              ×
            </span>
            <h3 className="box">Add {currentField}</h3>
            <ReactQuill
              value={richTextValues[currentField] || ""}
              onChange={(value) => handleRichTextChange(currentField, value)}
              modules={{ toolbar: toolbarOptions }}
              className="custom-react-quill"
            />
            <button onClick={closeModal} className="submit-btn">
              Save {currentField}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OnlineDetailCourses;
