import axios from "axios";
import apiService from "./environment";

const getPresignedUrl = async (filename) => {
    try {
      const response = await axios.get(
        apiService.getPresignedUrlforCourseImage(filename)
      );

      console.log("Presigned URL response:", response.data); 
      return response.data;
    } catch (error) {
      console.error("Error fetching presigned URL:", error);
      return null;
    }
  };

   const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const presignedUrl = await getPresignedUrl(file.name);

        if (!presignedUrl) {
          console.error("No presigned URL returned.");
          return;
        }

        console.log("Uploading file to S3 with URL:", presignedUrl); 

        const res = await axios.put(presignedUrl, file, {
          headers: { "Content-Type": file.type },
        });

        console.log("Upload success:", res); // Log response after successful upload
        return presignedUrl.split("?")[0] ;
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };
  export  {handleImageUpload}